<template>
	<v-container
		fluid
	>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="items"
							item-key="id"
							:footer-props="{'items-per-page-options':[10,25,50]}"
						>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { globalMixIn } from '@/App.vue'

export default {
	data: () => ({
		loading: false,
		headers: [
			{ text: 'ID', value: 'id' },
			{ text: 'Name', value: 'name' },
			{ text: 'Latest email', value: 'latestEmail' },
			{ text: 'Total sent', value: 'totalSent', sort: (a, b) => {
					if (a === `100+` || !b) return 1
					if (!a || b === `100+`) return -1
					return a-b
				}
			},
		],
		items: [],
	}),
	methods: {
		refreshContent () {
			this.loading = true
			this.getApi(`https://api.sendinblue.com/v3/smtp/templates?limit=500&templateStatus=true`, { headers: { 'api-key': process.env.VUE_APP_SIB_API_KEY } })
				.then(res => {
					this.items = res.templates
					this.items.forEach(o => {
						this.checkLatestMailInTemplate(o)
					})
				})
				.catch(e => this.$root.$snackbar(e.message, { color: 'error' }))
				.finally(() => this.loading = false)
		},
		checkLatestMailInTemplate(o) {
			this.getApi(`https://api.sendinblue.com/v3/smtp/statistics/events?startDate=2017-01-01&endDate=${this.dayjs().format('YYYY-MM-DD')}&limit=100&templateId=${o.id}&event=requests`, { headers: { 'api-key': process.env.VUE_APP_SIB_API_KEY } })
				.then(res => {
					if (res.events) {
						const foundIndex = this.items.findIndex(x => x.id == o.id)
						this.items[foundIndex].latestEmail = res.events[0].date.substr(0,10)
						this.items[foundIndex].totalSent = res.events.length === 100 ? `100+` : res.events.length
					}
				})
				.catch(e => this.$root.$snackbar(e.message, { color: 'error' }))
				.finally(() => this.loading = false)
		},
	},
	mixins: [globalMixIn],
	mounted () {
		this.refreshContent()
	},
	name: `SendinblueTemplates`,
}
</script>
